export default function extractJsonFromText(text) {
  // Find the starting index of the JSON object
  const startIndex = text.indexOf('{');
  // Find the ending index of the JSON object
  const endIndex = text.lastIndexOf('}') + 1;

  // Extract the JSON string from the text
  const jsonString = text.substring(startIndex, endIndex);

  // Parse the JSON string into a JavaScript object
  try {
    const jsonObject = JSON.parse(jsonString);
    return jsonObject;
  } catch (e) {
    console.error('Failed to parse JSON:', e);
    return null; // or you could throw an error depending on your error handling strategy
  }
}
