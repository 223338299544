<template>
  <div class="intent-display-drawer">
    <h1 class="intent-display-drawer__title">{{ searchTerm }}</h1>
    <button class="ed-btn ed-btn__blim" @click="goToLibrary">
      <i class="bx bx-library"></i> Another Aspect
    </button>
    <hr/>
    <div class="intent-display-drawer__content" v-html="markedResult"></div>
    <button v-if="showSubmitButton && !showEvaluateButton" @click="submitAnswers" class="ed-btn__blim" :disabled="isSubmitDisabled">Submit</button>
    <button v-if="showEvaluateButton" @click="evaluateAnswers" class="ed-btn__blim" :disabled="isEvaluateDisabled" >
      <span v-if="isLoading" class="spinner-border text-primary"></span>
      <span v-else>Evaluate</span>
    </button>
    <!-- <button @click="toggleAnswerMode" class="ed-btn__blim">Toggle Answer Mode</button> -->
    <p>Total Questions: {{ totalQuestions }}</p>
    <p>Correct Answers: {{ correctCount }}</p>
  </div>
</template>

<script>

import extractJsonFromText from '@/utils/extractJsonFromText';

export default {
  data() {
    return {
      test: 'title',
      intentFetchedResult: '',
      correctAnswers: {},
      userMarkedCorrectAnswers: {},
      totalQuestions: 0,
      correctCount: 0,
      showSubmitButton: false,
      showEvaluateButton: false,
      isSubmitDisabled: false,
      isEvaluateDisabled: false,
      isLoading: false,
      isAnswerMode: true,
      notes: {},
      searchTerm: '',
      intentId: '',
      review: '',
      goal: null,
      assessmentId: null,
    };
  },

  async mounted() {
    this.notes = this.defaultNotes; // Initialize notes
    // await this.fetchInfoUsingIntents();
    this.addObserver();
  },

  computed: {
    markedResult() {
      return this.intentFetchedResult.replace(/<p>\n<strong>Answers<\/strong>:([\s\S]*?)<\/p>/, '');
    },
    isGameConnected() {
      // console.log('xvf', 'is Game connected', this.$store.state.game.connected);
      return this.$store.state.game.connected;
    },
  },

  methods: {
    async setCurrentThought(searchTerm, intentId, goal = null) {
      this.searchTerm = searchTerm;
      this.intentId = intentId;
      this.goal = goal;
      await this.fetchInfoUsingIntents();
    },

    async fetchInfoUsingIntents() {
      await this.$store.dispatch('assessments/askEddieForResponse', {
        prompt: this.searchTerm,
        intentId: this.intentId,
        callback: (decodedChunk) => {
          this.intentFetchedResult += decodedChunk;
        },
      });
      const payload = {
        label: this.searchTerm,
        type: 'OBJECTIVE',
        status: 'PENDING',
        question: this.intentFetchedResult,
      };

      if (this.goal) {
        payload.goal = this.goal;
      }
      await this.$store.dispatch('assessments/createAssessment', payload);
      this.extractCorrectAnswers();
      this.showSubmitButton = true; // Show the Submit button after streaming ends
    },

    extractCorrectAnswers() {
      const answerSection = this.intentFetchedResult.match(/<p>\n<strong>Answers<\/strong>:([\s\S]*?)<\/p>/);
      if (answerSection) {
        const answers = answerSection[1].trim().split('<br>\n');
        answers.forEach((answer) => {
          const [question, correctAnswer] = answer.split('. ');
          this.correctAnswers[`Q${question.replace('Q', '')}`] = correctAnswer;
        });
        this.totalQuestions = answers.length;
      }
    },

    addObserver() {
      this.$nextTick(() => {
        const radioButtons = document.querySelectorAll('.question__option');
        radioButtons.forEach((radio) => {
          radio.addEventListener('change', this.storeUserAnswer);
        });
      });
    },

    storeUserAnswer(event) {
      if (!this.isAnswerMode) return;
      const selectedRadio = event.target;
      const questionName = selectedRadio.name;
      const selectedValue = selectedRadio.value;
      this.$set(this.userAnswers, questionName, selectedValue);
    },

    submitAnswers() {
      this.correctCount = 0;
      // console.log('xvf', this.correctAnswers);
      const getuserAnswers = {};
      const radios = document.querySelectorAll('.question__option');
      radios.forEach((e) => {
        const radio = e;
        if (radio.checked) {
          getuserAnswers[radio.name] = radio.value;
        }
        radio.disabled = true;
      });

      Object.keys(this.correctAnswers).forEach((questionName) => {
        const correctAnswer = this.correctAnswers[questionName];
        const userAnswer = getuserAnswers[questionName];
        const questionContainer = document.querySelector(`div.question__container[data-query="${questionName}"]`);
        // const existingNote = questionDiv.querySelector('.note');
        const note = document.createElement('p');
        note.className = 'note';

        if (correctAnswer === userAnswer) {
          console.log('xvf', questionName, correctAnswer, userAnswer);
          this.userMarkedCorrectAnswers[questionName] = correctAnswer;
          note.style.color = '#0c706b';
          questionContainer.style.color = '#0c706b';
          note.textContent = 'This is correct';
          this.correctCount += 1;
        } else {
          note.textContent = 'This is wrong';
          note.style.color = '#fe330e';
          questionContainer.style.color = '#fe330e';
        }
        questionContainer.appendChild(note);
      });

      this.showEvaluateButton = true; // Show the Evaluate button after Submit is clicked
      this.isSubmitDisabled = true; // Disable the Submit button
    },

    async evaluateAnswers() {
      this.isEvaluateDisabled = true;
      this.isLoading = true;
      const htmlStructureOfQuestions = document.querySelector('.intent-display-drawer__content').innerHTML;
      const prompt = `Question chunk: ${htmlStructureOfQuestions} and correct answer of users are ${JSON.stringify(this.userMarkedCorrectAnswers)}`;
      await this.$store.dispatch('assessments/askEddieForResponse', {
        prompt,
        intentId: '66826b442d18558c8762bb15',
        callback: (decodedChunk) => {
          this.review += decodedChunk;
        },
      });
      const reviews = extractJsonFromText(this.review);
      this.updateNotes(reviews);
      this.isLoading = false;
      console.log('xvf', 'user review', this.review);
      // trigger event for watching video
      const eventPayload = {
        verb: 'ASSESSMENT',
        value: {
          intentId: this.$route.params.intentId,
          topic: this.$route.params.searchTerm,
          questions: htmlStructureOfQuestions,
          correct: this.correctCount,
          total: this.totalQuestions,
        },
        dataSource: 'tj-client-web',
      };
      const status = 'CLEARED';
      const result = ((this.correctCount / this.totalQuestions) * 100) >= 90 ? 'PASS' : 'FAIL';
      const { goal } = await this.$store.dispatch('assessments/updateAssessment', { status, result });
      if (this.isGameConnected && goal) {
        const response = {
          data: {
            type: 'goal',
            status: 200,
            goal,
          },
        };
        window.parent.postMessage(
          JSON.stringify(response),
          process.env.VUE_APP_GAME_URI,
        );
      }
      // await this.updateAssessment(status, result);
      this.$store.dispatch('eventToEventStore', eventPayload);
    },

    toggleAnswerMode() {
      this.isAnswerMode = !this.isAnswerMode;
      this.updateNotes(this.alternateNotes);
    },

    goToLibrary() {
      this.$router.push({ name: 'WidgetView', params: { name: 'KaaroWidget' }, query: { toggleKaaroWidgetView: '[]', setCurrentThought: `[${this.$route.params.searchTerm}]` } });
    },

    updateNotes(newNotes) {
      this.notes = newNotes;
      Object.keys(this.notes).forEach((questionName) => {
        const questionDiv = document.querySelector(`div[data-query="${questionName}"]`);
        if (questionDiv) {
          const existingNote = questionDiv.querySelector('.note');
          if (existingNote) {
            existingNote.textContent = this.notes[questionName];
          } else {
            const note = document.createElement('p');
            note.className = 'note';
            note.style.color = 'blue'; // Default color for new notes
            note.textContent = this.notes[questionName];
            questionDiv.appendChild(note);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.intent-display-drawer {
    padding: 2rem;

    &__title {
      text-transform: capitalize;
    }

    &__content {
      padding: 1rem;
    }
}
</style>
